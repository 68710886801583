import { useStaticQuery, graphql } from 'gatsby'

export const getCallToActionData = () => {
  const { allStrapiCallToAction } = useStaticQuery(CallToActionQuery)
  return allStrapiCallToAction.nodes;
}

const CallToActionQuery = graphql`
query {
  allStrapiCallToAction(sort: {fields: createdAt, order: ASC})  {
    nodes{
    description
    title
    featuredImage {
      url
    }
    button01 {
      name
      url
    }
    button02 {
      name
      url
    }
  }
}
}
`