import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { Box } from 'theme-ui'

const styles = {
  image: image => ({
    display: [`none`, null, `block`],
    height: `120%`,
    width: `1/2`,
    minWidth: 380,
    maxHeight: 500,
    borderRadius: `default`,
    backgroundImage: `url(${image})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `contain`,
    backgroundPosition: `bottom left`,
    overflowX: `hidden`,
    position: `absolute`,
    bottom: 0,
    left: 30,
    zIndex: 2
  }),
  imageaafh: image => ({
    display: [`none`, null, `block`],
    height: `120%`,
    width: `1/2`,
    minWidth: 275,
    maxHeight: 376,  
    borderRadius: `default`,
    backgroundImage: `url(${image})`,
    backgroundRepeat: `no-repeat`,
    backgroundSize: `contain`,
    backgroundPosition: `bottom left`,
    overflowX: `hidden`,
    position: `absolute`,
    bottom: 45,
    left: 30,
    zIndex: 2
  })
}

export default ({callFrom='Digital Collection'}) => {
  const { allStrapiCallToAction } = useStaticQuery(testimonialQuery)
  const publicURL =
    callFrom === 'Digital Collection'
      ? allStrapiCallToAction.nodes[0].featuredImage[0].url
      : allStrapiCallToAction.nodes.length > 1
      ? allStrapiCallToAction.nodes[1].featuredImage[0].url
      : ''

  if (!publicURL) return ''

  return <Box sx={callFrom==='Digital Collection'?styles.image(publicURL || {}):styles.imageaafh(publicURL || {})}></Box>
}

const testimonialQuery = graphql`
query {
  allStrapiCallToAction(sort: {fields: createdAt, order: ASC})  {
    nodes{
    featuredImage {
      url
    }
  }
}
}
`
