import { useStaticQuery, graphql } from 'gatsby'

// :::: GET Author Description from StrapiAuthors ::::
export const allStrapiBlogTag = () => {
  const { blogs, videos, events } = useStaticQuery(allStrapiBlogTagDetail)

  const item = [...blogs.nodes, ...events.nodes, ...videos.nodes]
  const tags = []
  let allTags = []

  item.map(ele => {
    ele.tags.map(obj => {
      allTags.push(obj)
    })
  })

  allTags.map(x =>
    tags.filter(a => a.name == x.name).length > 0 ? null : tags.push(x)
  )

  return tags
}

const allStrapiBlogTagDetail = graphql`
  query {
    blogs: allStrapiBlog {
      nodes {
        tags {
          id
          name
        }
      }
    }

    events: allStrapiEvent {
      nodes {
        tags {
          id
          name
        }
      }
    }

    videos: allStrapiVideo {
      nodes {
        tags {
          id
          name
        }
      }
    }
  }
`
