import { graphql } from 'gatsby'
import PostsPage from '../containers/Posts'

export default PostsPage

export const pageQuery = graphql`
  query PostsPageQuery($includeTimeToRead: Boolean!, $imageQuality: Int!) {
    featuredRecentVideo: allVideo(
      filter: { private: { ne: true }, featured : { eq : true }}
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        ...VideoInformation
      }
    }
    featuredRecentBlog: allBlog(
      filter: { private: { ne: true }, featured : { eq : true } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        ...BlogInformation
      }
    }

    featuredRecentEvents: allEvent(
      filter: { private: { ne: true }, featured : { eq : true } }
      sort: { fields: [eventdate], order: DESC }
    ) {
      nodes {
        ...EventInformation
      }
    }

    recentVideo: allVideo(
      filter: { private: { ne: true }}
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        ...VideoInformation
      }
    }
    recentBlog: allBlog(
      filter: { private: { ne: true } }
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        ...BlogInformation
      }
    }

    recentEvents: allEvent(
      filter: { private: { ne: true } }
      sort: { fields: [eventdate], order: DESC }
    ) {
      nodes {
        ...EventInformation
      }
    }

    featuredPartners: allPartner(
      filter: { private: { ne: true }}
      sort: { fields: [date], order: DESC }
    ) {
      nodes {
        ...PartnerInformation
      }
    }
  }
`
