import React from 'react'
import MemphisPattern from '@components/MemphisPattern'
import HeroBoxed from '@widgets/HeroBoxed'
import Content from './Testimonial.Content'
import Photo from './Testimonial.Photo'

const Testimonial = (props) => {
  return(
  <HeroBoxed.Wrapper>
    <HeroBoxed.RightColumn>
      <Photo callFrom={props.callFrom} />
    </HeroBoxed.RightColumn>
    <HeroBoxed.LeftColumn>
      <Content callFrom={props.callFrom} />
    </HeroBoxed.LeftColumn>
    <MemphisPattern />
  </HeroBoxed.Wrapper>
)}

export default Testimonial
