import React from 'react'
import { Box, Heading, Button } from 'theme-ui'
import { Link } from 'gatsby'
import { getCallToActionData } from '../../../../themes/gatsby-theme-flexiblog-personal/src/helpers/nr-callToAction.helper'

/**
 * Shadow me to add your own content
 */

const styles = {
  wrapper: {
    ml: 3
  },
  heading: {
    color: `alpha`
  },
  running: {
    color: `omegaDark`,
    fontWeight: `body`
  }
}

export default ({callFrom='Digital Collection'}) => {
  const list=getCallToActionData();
  const callToAction =
    callFrom === 'Digital Collection' ? list[0] : list.length > 1 ? list[1] : {}
  return (
    <Box sx={styles.wrapper}>
      {
        callFrom === 'AAFH'
        ? <>
        <Heading sx={{...styles.heading, fontSize: `1.8rem`}}>
      <div dangerouslySetInnerHTML={{ __html: callToAction.title }} />
      </Heading>
      <Heading sx={{...styles.running, fontSize: `1rem`}}>
        {callToAction.description}
      </Heading>
        </>
        : <>
        <Heading variant='h1' sx={styles.heading}>
        <div dangerouslySetInnerHTML={{ __html: callToAction.title }} />
        </Heading>
        <Heading variant='h2' sx={styles.running}>
          {callToAction.description}
        </Heading>
        </>
      }
      <Box variant='buttons.group' sx={styles.buttons}>
        {callToAction.button01?<Button as={Link} to={callToAction.button01[0].url}>
          {callToAction.button01[0].name}
        </Button>:null}
        {callToAction.button02?<Button variant='white' as={Link} to={callToAction.button02[0].url}>
          {callToAction.button02[0].name}
        </Button>:null}
      </Box>
      {/* <Heading variant='h3'>Aubree Joyce</Heading> */}
    </Box>
  )
}
