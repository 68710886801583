import React from 'react'
import { Box } from 'theme-ui'
import { Layout, Stack, Hero, Main, Sidebar, PreFooter } from '@layout'
import CardList from '@components/CardList'
import Sticky from '@components/Sticky'
import Divider from '@components/Divider'
import Pagination from '@components/Pagination'
import Seo from '@widgets/Seo'
import Categories from '@widgets/Categories'
import Tags from '@widgets/Tags'
import NewsletterCompact from '@widgets/NewsletterCompact'
import Testimonial from '@widgets/Testimonial'
import Social from '@widgets/Social'
import HeroComponent from '../components/Hero/Hero'
import { useBlogTags, useBlogCategories, getReadTimeFromBlog } from '@helpers-blog'
import { readingTime } from 'reading-time-estimator'

import { attachExcerptToEvent, getStaticPageSeoDetails } from '@utils-blog'
import { allStrapiBlogTag } from '../helpers/nr- blog-tag'
const Posts = ({
  data: {
    paginatedPosts = {},
    featuredRecentBlog = {},
    featuredRecentVideo = {},
    featuredRecentEvents = {},
    recentBlog = {},
    recentVideo = {},
    recentEvents = {}
  },
  ...props
}) => {
  const { pageContext: { services = {}, basePath, siteUrl } = {} } = props
  // const tags = useBlogTags()
  let categories = useBlogCategories()
  const sliderRef = React.useRef()
  attachExcerptToEvent(featuredRecentBlog)
  attachExcerptToEvent(featuredRecentEvents)
  attachExcerptToEvent(featuredRecentVideo)
  attachExcerptToEvent(recentBlog)
  attachExcerptToEvent(recentEvents)
  attachExcerptToEvent(recentVideo)
  // attachCategoriesToEvent(featuredBlog)
  // attachCategoriesToEvent(featuredVideo)
  // attachCategoriesToEvent(featuredEvents)

  const eventsData = featuredRecentEvents.nodes.map(arr => {
    arr.date = arr.eventdate
    return arr
  })
  categories = categories.filter(item => item.type === 'Blog')

  const tags = allStrapiBlogTag()

  featuredRecentBlog.nodes.forEach(ele => {
    ele.bodyContent = getReadTimeFromBlog(ele.title)
    if (ele.bodyContent && ele.bodyContent.body) {
      const text = ele.bodyContent.body.data.body.replace(/(<([^>]+)>)/gi, '')
      ele.readingTime = readingTime(text, 220)
    }
  })

  recentBlog.nodes.forEach(ele => {
    ele.bodyContent = getReadTimeFromBlog(ele.title)
    if (ele.bodyContent && ele.bodyContent.body) {
      const text = ele.bodyContent.body.data.body.replace(/(<([^>]+)>)/gi, '')
      ele.readingTime = readingTime(text, 220)
    }
  })

  const data = [...eventsData, ...featuredRecentBlog.nodes, ...featuredRecentVideo.nodes]
  const featuredRecentPosts = data.sort((a, b) => {
    return new Date(b.date) - new Date(a.date)
  })

  let seoData = getStaticPageSeoDetails('shared.home-page')

  const homeSeoData = {
    ...seoData,
    Home: { ...seoData?.Home, keywords: seoData?.Home?.keywords?.split('\n') }
  }

  return (
    <Layout {...props}>
      <Seo
        title={homeSeoData?.Home?.metaTitle}
        description={homeSeoData?.Home?.metaDescription}
        keywords={homeSeoData?.Home?.keywords}
        thumbnail={homeSeoData?.Home?.metaImage}
      />
      <Hero
        pt={4}
        pb={5}
        sx={{
          background: t =>
            `linear-gradient(
              0deg,
              ${t.colors.omegaLighter},
              ${t.colors.background}
            )`
        }}
      >
        <Divider space={3} />
        <Box sx={{ position: `relative`, zIndex: 3 }}>
          <CardList
            nodes={featuredRecentPosts}
            variant={['horizontal-hero']}
            limit={3}
            omitFooter
            slider
            autoPlay
            fade
            arrows={false}
            controlPosition='bottom'
            ref={sliderRef}
            loading='eager'
          />
          <Box sx={{ display: [`none`, null, `block`] }}>
            <Divider />
            <CardList
              nodes={featuredRecentPosts}
              variant={['horizontal-md', 'horizontal-aside']}
              limit={3}
              columns={[1, 0, 3]}
              omitCategory
              asNavFor={sliderRef}
              loading='eager'
            />
          </Box>
        </Box>
      </Hero>
      <Divider />
      <Stack>
        <Main>
          <Testimonial callFrom={'AAFH'} />
        </Main>
      </Stack>
      <Divider />
      <Stack title={'Recent Articles'} titleLink={'blog'}>
        <Main>
          <CardList
            nodes={recentBlog.nodes}
            limit={4}
            callFrom='Blog'
            columns={[1]}
            variant={['horizontal-md', 'horizontal']}
          />
        </Main>
        <Sidebar>
          <Categories categories={categories} />
          <Divider />
          <Tags tags={tags} />
          <Divider />
          {/* <Social /> */}
        </Sidebar>
      </Stack>
      <Divider space={35} />
      <Stack>
        <Main>
          <Testimonial callFrom={'Digital Collection'} />
        </Main>
      </Stack>
      <Divider space={50} />
      <Stack
        title={'Latest Video'}
        titleLink={'videos'}
        direction={['column', 'column', 'column', 'row']}
      >
        <Main
          sx={{
            display: [null, `flex`],
            flexDirection: [`column`, null, null, `row`]
          }}
        >
          <Divider space={2} />
          <CardList
            nodes={recentVideo.nodes}
            limit={2}
            callFrom="Video"
            columns={[1, 1, 3, 1]}
            variant={['horizontal-md', 'horizontal']}
          />
          <Divider space={2} />
        </Main>
        <Sidebar
          sx={{
            pl: [0, null, null, 3],
            display: [`flex`, `flex`],
            flexDirection: [`column`, null, null, `row`]
          }}
        >
          <CardList
            nodes={recentVideo.nodes}
            limit={1}
            callFrom="Video"
            skip={2}
            columns={[1]}
            variant={['horizontal-md', 'horizontal', 'horizontal', 'vertical']}
          />
        </Sidebar>
      </Stack>

      <Divider space={50} />
      <Stack
        title={'Latest Events'}
        titleLink={'events'}
        direction={['column', 'column', 'column', 'row']}
      >
        <Main
          sx={{
            display: [null, `flex`],
            flexDirection: [`column`, null, null, `row`]
          }}
        >
          <Divider space={2} />
          <CardList
            nodes={recentEvents.nodes}
            limit={2}
            callFrom='EventPage'
            columns={[1, 1, 3, 1]}
            variant={['horizontal-md', 'horizontal']}
          />
          <Divider space={2} />
        </Main>
        <Sidebar
          sx={{
            pl: [0, null, null, 3],
            display: [`flex`, `flex`],
            flexDirection: [`column`, null, null, `row`]
          }}
        >
          <CardList
            nodes={recentEvents.nodes}
            limit={1}
            skip={2}
            callFrom='EventPage'
            columns={[1]}
            variant={['horizontal-md', 'horizontal', 'horizontal', 'vertical']}
          />
        </Sidebar>
      </Stack>
      <PreFooter>
        <Pagination {...paginatedPosts.pageInfo} basePath={basePath} />
      </PreFooter>
    </Layout>
  )
}

export default Posts
